import './App.css';
import RaceResults from './RaceResult/RaceResult';

function App() {
  return (
    <div className="container">
      <RaceResults/>
    </div>
  );
}

export default App;
